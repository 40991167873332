.VTComponent {
    box-sizing: border-box;
    margin:0;
    border:0;
    padding:0;
    outline:0;
}

.VTComponent:focus {
    outline: 0;
}
